<template>    
    <div style="background-color: #f2f7ff">
        <Sidebar></Sidebar>

        <div id="main">
            <header class="mb-3">
                <a href="#" class="burger-btn d-block d-xl-none">
                    <i class="bi bi-justify fs-3"></i>
                </a>
            </header>

            <div class="page-heading">
                <h3>Orders</h3>
            </div>

            <div class="page-content">
                <section class="row flex-column">
                    <div class="col-12 col-lg-12">
                        <div class="row">
                            <div class="col-12 col-xl-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4>Latest Orders</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table class="table table-hover table-lg">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Subtotal</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="order in orders" :key="order.id" @click="data = order, id = order.id">
                                                        <td>
                                                            <p class="font-bold mb-0">ORD-{{order.id}}</p>
                                                        </td>
                                                        <td>
                                                            <p class="mb-0">Rp. {{ Number(order.subtotal).toLocaleString() }}</p>
                                                        </td>
                                                        <td>
                                                            <p class="mb-0">{{ order.status }}</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-12 pb-2" v-if="data">
                        <div class="card m-0">
                            <div class="container pt-4 pb-4">
                                <section class="section3">
                                    <h6 class="mb-4">Rincian Pesanan</h6>

                                    <ul class="lists mb-4">
                                        <li v-for="log in data.logs" :key="log.id" class="list mb-3">
                                            <div>
                                                <p>{{ log.product_name }}</p>
                                                <p class="detail">{{ log.detail }}</p>
                                            </div>
                                            <div>
                                                <p class="qty">x{{ log.quantity }}</p>
                                                <p class="total">Rp{{ Number(log.subtotal).toLocaleString() }}</p>
                                            </div>
                                        </li>
                                    </ul>

                                    <h6 class="mb-4">Ongkir</h6>

                                    <ul class="lists">
                                        <li class="list mb-3">
                                            <div>
                                                <p>{{ data.ship.toUpperCase() }}</p>
                                                <p class="detail">{{ data.ship_service }}</p>
                                            </div>
                                            <div>
                                                <!-- <p class="qty">x{{ log.quantity }}</p> -->
                                                <p class="total">Rp{{ Number(data.shipping_cost).toLocaleString() }}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <footer>
                <div class="footer clearfix mb-0 text-muted">
                    <div class="float-start">
                        <p>2021 &copy; Rinyuakng</p>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import axios from 'axios'

export default {
    components: {
        Sidebar
    },
    data() {
        return {
            id: null,
            token: localStorage.getItem('token'),
            orders: '',
            data: '',
            nowSrc: ''
        }
    },
    methods: {
        getOrder() {
            axios.get(`mitra/my-order?token=${this.token}`)
				.then(res => {
					this.orders = res.data;

                    this.data = null;
				})
				.catch(err => {
					console.log(err.response.data);
				});
        }
    },
    created() {
        if (this.token) this.getOrder();
    }
}
</script>

<style scoped>

.lists {
    padding: 0;
    margin: 0;
}

.list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.list p {
    margin: 0;
}

.list .detail {
    color: gray;
    font-size: 12px;
    margin: 0;
}

.list .qty {
    color: gray;
    font-size: 14px;
    text-align: right;
}

.list .total {
    font-size: 13px;
}

</style>